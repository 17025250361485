
function hidePagamentoLoading() {
  $('.pagamento-loading').fadeOut();
  $('.loading-hidden').each(function() {
      $(this).removeClass('loading-hidden');
  });
}

var url_geral = $("#infos").data('url-base');
var pag_atual = $("#infos").data('pag-atual');
$(document).ready(function(){
//somente se for a página de pagamento  
if(pag_atual == "pagamento"){   
  $("#concluir").hide();
  function formataMoeda(v, c, d, t){
      var n = v, c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
      return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
  }   
//sessao
var  dataString  = {  
  acao: 'request_session'
}; 
$.ajax({
type: "POST",
url: url_geral+"acoes/app/pagamentos/pagseguro.php",
data: dataString, 
cache: false,
success: function( response ){
  response = $.parseJSON(response);
  var id_sessao = response.id_sessao[0];
  PagSeguroDirectPayment.setSessionId(id_sessao);
  
  //console.log(id_sessao);

//meios de pagamento
  PagSeguroDirectPayment.getPaymentMethods({ 
      amount: $("#valorPagto").val(),                         
      success: function(response) { 
          $("#modulo").val("Pagseguro");
          var sender_hash = PagSeguroDirectPayment.getSenderHash();
          $("#sender_hash").val(sender_hash); 
          if(sender_hash != ""){
              hidePagamentoLoading();
              $("#concluir").show();
          }
          //console.log(response.paymentMethods.CREDIT_CARD.options); 
          $('.loading-formas-pag').hide();
          $('.loading-formas-pag').html('');  
          var arr_meios = ["CREDIT_CARD","BOLETO"];
          $.each(response.paymentMethods, function(index, value){
              if(jQuery.inArray(index,arr_meios)!=-1){
                  $("#"+value.name).show();
                  $("#"+value.name).data('cod',value.code);
                  //$("#"+value.name).attr('title',value.code);
              }
              //console.log(response.paymentMethods);
          });
      }, error: function(response) { 
           //tratamento do erro 
           //console.log(response.errors);
           var  dataString  = {  
              origem: 'meios_pag',
              msg: response.errors,
              acao: 'log'
           }; 
           $.ajax({
              type: "POST",
              url: url_geral+"acoes/app/pagamentos/grava_log.php",
              data: dataString, 
              cache: false,
              success: function( response ){
              }  
           });
      }, 
      complete: function(response) { 
      } 
  });

//bandeira do cartão
  $('#numero_cartao').on('change', function() {
      PagSeguroDirectPayment.getBrand({cardBin: $("#numero_cartao").val(),
      success: function(response) {
        //bandeira encontrada
        var bandeira = response.brand.name;
        $("#bandeira").val(bandeira);
        // $("#cvv").attr('min','0');
        // $("#cvv").attr('max',''+response.brand.cvvSize+'');
        $("#cvv").attr('maxlength',''+response.brand.cvvSize+'');
        $("#msg_cvv").html('<br>Últimos '+response.brand.cvvSize+' números no verso do cartão');
        $("#img_bandeira").html('<img src="'+url_geral+'static/img/pagamentos/brands/'+bandeira+'.png">');
        //parcelamento
          PagSeguroDirectPayment.getInstallments({
          amount: $("#valorPagto").val(), 
          brand: bandeira,
          maxInstallmentNoInterest: $("#max_parcelas").val(), 
              success: function(response) { 
               //opções de parcelamento disponível 
               console.log(response.installments);
               $("#parcelas").html("");
               $("#parcelas").append('<option value="">Parcelas</option>');
               $.each(response.installments, function (index, value) {
                  $.each(value, function (index, value) {
                      var juros = this.interestFree == true ? "( Sem juros )" : "";   
                      $("#parcelas").append('<option value="'+this.quantity+'x'+this.installmentAmount+'">'+this.quantity+'X de R$ '+formataMoeda(this.installmentAmount)+' = R$ '+formataMoeda(this.totalAmount)+' '+juros+'</option>');
                  });
               });

                $(".msg_invalido").hide();
              }, 
              error: function(response) { 
               //tratamento do erro. 
               console.log(response.errors);
               console.log(amount);
               var  dataString  = {  
                      origem: 'parcelamento',
                      msg: response.errors,
                      acao: 'log'
                   }; 
                   $.ajax({
                      type: "POST",
                      url: url_geral+"acoes/app/pagamentos/grava_log.php",
                      data: dataString, 
                      cache: false,
                      success: function( response ){
                      }  
                   });
              }, 
              complete: function(response) { 
              } 
          });
      }, 
      error: function(response) {
         //tratamento do erro 
         console.log(response);
         $("#bandeira").val("");
         $("#numero_cartao").val("");
         $("#cvv").val("");
         $(".msg_invalido").show();
         $("#msg_cvv").hide();
         $("#img_bandeira").html('');
          var  dataString  = {  
              origem: 'brand',
              msg: response,
              acao: 'log'
           }; 
           $.ajax({
              type: "POST",
              url: url_geral+"acoes/app/pagamentos/grava_log.php",
              data: dataString, 
              cache: false,
              success: function( response ){
              }  
           });
      }, 
      complete: function(response) {
              //console.log(response);
      }
      });

  });
//tranfere dados para o formulário
  $('.finaliza-pedido').on('click', function() {

      var dta_nasc = $("#nascDia").val()+"/"+$("#nascMes").val()+"/"+$("#nascAno").val();
      $("#cvv_cartao_env").val($("#cvv").val());
      $("#numero_cartao_env").val($("#numero_cartao").val());
      $("#nome_cartao_env").val($("#nome_cartao").val());
      $("#parcelas_cartao_env").val($("#parcelas").val());
      $("#validademes_cartao_env").val($("#validadeMes").val());
      $("#validadeano_cartao_env").val($("#validadeAno").val());
      $("#cpf_cartao_env").val($("#cpf_cartao").val());
      $("#nasc_cartao_env").val(dta_nasc);

  }); 
//tranfere parcela para o formulário
  $('#parcelas').on('change', function() {
      
      $("#parcelas_cartao_env").val($("#parcelas").val());
      
  }); 
//token cartão
  $('.campos_cartao_token').on('change', function() {

      if($("#numero_cartao").val() != "" && $("#cvv").val() !="" && $("#validadeMes").val() !="" && $("#validadeAno").val() !=""){

          var param = {
              cardNumber: $("#numero_cartao").val(),
              cvv: $("#cvv").val(),
              expirationMonth: $("#validadeMes").val(),
              expirationYear: $("#validadeAno").val(),
          success: function(response) {
              var token_cartao = response.card.token;
              $("#cartao_token").val(token_cartao); 
          }, 
          error: function(response) { 
              //console.log(response.errors);

              var  dataString  = {  
                      origem: 'token_cartao',
                      msg: response.errors,
                      acao: 'log'
              }; 
              $.ajax({
                  type: "POST",
                  url: url_geral+"acoes/app/pagamentos/grava_log.php",
                  data: dataString, 
                  cache: false,
                  success: function( response ){
                  }  
              });

          },
          complete: function(response) { 
              
          } 
          } 
          if($("#bandeira").val() != '') { 
              param.brand = $("#bandeira").val();
          } 
          PagSeguroDirectPayment.createCardToken(param);
      }
  }); 
//fim script
}  
});
//fim sessao
}
//somente se a página atual for pagamento
});


/* =================== PAGAMENTO =================== */
jQuery(document).ready(function ($) {

  // Exibe/Oculta os campos da forma de pagamento escolhida
  $('input[name="forma-pagamento"]').on('change', function () {

      $('.forma-pag-content').slideUp();
      $('.forma-pag-content .campo').prop('disabled', true);
      $('.forma-pag-content .campo').prop('required', false);

      $('.forma-pag-content[data-forma="' + $(this).val() + '"]').slideDown();
      $('.forma-pag-content[data-forma="' + $(this).val() + '"] .campo').prop('disabled', false);
      $('.forma-pag-content[data-forma="' + $(this).val() + '"] .campo').prop('required', true);

  });

});
