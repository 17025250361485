/* ===================CRIA PAGAMENTO =================== */
jQuery(document).ready(function () {

  $('.add-curso').on('click', function (e) {
    e.preventDefault();
    var idCurso = $(this).data('id-curso');

    var dados = {
      curso: idCurso,
      acao: 'insert'
    };

    $.ajax({
      url: $("#infos").data('url-base') + 'acoes/app/usuarios/grava_pedido.php',
      type: 'POST',
      data: dados,
      beforeSend: function () {
        showLoading();
      },
      success: function (data) {
        console.log(data);
        data = $.parseJSON(data);
        if (data.status == "ok") {
          location.href = $("#infos").data('url-base') + 'minha-conta/pagamento/' + data.hash;
        }
      },
      error: function (xhr, type, exception) {
        volta_submit();
        showAlert('Erro', 'Não foi possível se inscrever.', 'error');
        console.log("ajax error response type " + type);
      }
    });
  });

  $('.add-curso-session').on('click', function (e) {
    e.preventDefault();
    var idCurso = $(this).data('id-curso');

    var dados = {
      curso: idCurso,
      acao: 'insert'
    };

    $.ajax({
      url: $("#infos").data('url-base') + 'acoes/app/usuarios/grava_sessao_curso.php',
      type: 'POST',
      data: dados,
      beforeSend: function () {
        showLoading();
      },
      success: function (data) {
        console.log(data);
        data = $.parseJSON(data);
        if (data.status == "ok") {
          location.href = $("#infos").data('url-base') + 'minha-conta/entrar';
        }
      },
      error: function (xhr, type, exception) {
        volta_submit();
        showAlert('Erro', 'Não foi possível se inscrever.', 'error');
        console.log("ajax error response type " + type);
      }
    });
  });

  //Grava o pagamento plano
  $('.bt-registrar-pagamento').on('click', function (e) {
    e.preventDefault();
    var url_geral = $("#infos").data('url-base');
    var dados = $("#form-pagamento").serialize();
    $.ajax({
      type: "POST",
      url: url_geral + "acoes/app/pagamentos/grava_pagamento.php?acao=insert",
      data: dados,
      beforeSend: function () {
        showLoading();
      },
      success: function (data) {
        console.log(data);
        data = $.parseJSON(data);
        console.log(data);
        hideLoading();
        location.href = "" + url_geral + "minha-conta/confirmacao/order/" + data.hash + "?status=" + data.status_pedido + "&pag=" + data.status_pagamento;
      },
      error: function (xhr, type, exception, data) {

        data = $.parseJSON(data);
        hideLoading();
        showAlert('Erro', 'Não foi possível realizar essa operação.', 'error');
        console.log("ajax error response type " + type);
        //console.log(data);

      }
    });

  });

});
